html, body {
  font-size: medium;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  text-align: center;
  color: rgb(32, 30, 67);
  height: 100%;
  margin: 0;
}


.container {
  min-height: 100vh; 
  display: flex;
  flex-direction: column;
}

.main {
  width: 100%;
  background-color: rgb(238, 238, 238);
  padding: 20px;
}
h1{
  color: rgb(19, 75, 112);
  text-align: center;
}
h3 {
  color: rgb(80, 140, 155);
  padding: 20px;
  font-size: large;
}

p {
  padding: 20px;
  text-align: left;
  margin-left: 20px;

}

a {
color:rgb(32, 30, 67);
  }
  
  nav{
    text-align: center;
    margin-bottom: 10px;
    padding: 20px;
  }

.about {
  background-color: white;
  width: 1080px;
  height: 500px;
  margin: auto;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}

.project {
  background-color: rgb(238, 238, 238);
  display: flex;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  align-items: flex-start;
 }

.project-content {
  flex: 1; 
}

.project-image {
  width: 50%; 
  height: auto;
  margin-left: 5px; 
  margin-right: 30px;
}

.contact {
  width: 100%; 
  display: flex;
  text-align: center;
  align-items: flex-end;
justify-content: center;
margin-top: 150px;
margin-bottom: 300px;
}

.form {
  display: flex;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  align-items: center;
}

.appointment-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: rgb(80, 140, 155); 
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
}

.appointment-button:hover {
  background-color: rgb(32, 30, 67);
}
  
footer {
    padding: 0.5rem;
    font-size: 30px;
    color: rgb(238, 238, 238);
    text-align: center;
    background-color: rgb(80, 140, 155);
    margin-top: auto; 
  }
  